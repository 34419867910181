<template>
    <v-container fluid>
        <v-data-table
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="filteredItems"
            item-key="orden_CO"
            class="ma-2 elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            :height="'80vh'"
            fixed-header
        >
            <template v-slot:top>
                <div>
                    <v-row
                        no-gutter
                        style="background-color: #eeeeee"
                        :class="
                            $vuetify.breakpoint.xs
                                ? 'd-flex justify-center py-4 mx-0'
                                : 'd-flex justify-start py-4 mx-0'
                        "
                    >
                        <h3 class="pl-3">POC</h3>
                        <v-spacer v-if="!$vuetify.breakpoint.xs" />
                        <div class="pb-2 pr-3">
                            <v-btn
                                depressed
                                color="primary"
                                @click="openUploadFile"
                                rounded
                                :disabled="!uploadPocPermission"
                            >
                                <v-icon>mdi-plus</v-icon>
                                {{ !$vuetify.breakpoint.xs ? 'New' : '' }}
                            </v-btn>
                        </div>
                    </v-row>
                </div>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.date`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="dateToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.job`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.client`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="clientToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.project_id`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="referenceToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.orden_CO`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="orderToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.date`]="{ item }">
                <div class="my-4">
                    {{ `${item.year}-${item.month}` }}
                </div>
            </template>
            <template v-slot:[`item.total_amount_before_taxes`]="{ item }">
                <div class="my-4">
                    {{
                        formatNumber(
                            item.currency,
                            item.total_amount_before_taxes
                        )
                    }}
                </div>
            </template>
            <template v-slot:[`item.balance_to_bill`]="{ item }">
                <div class="my-4">
                    {{ formatNumber(item.currency, item.balance_to_bill) }}
                </div>
            </template>
            <template v-slot:[`item.billed_to_date`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item.billed_to_date) }}
                </div>
            </template>
            <template v-slot:[`item.estimated_margin`]="{ item }">
                <div class="my-4">{{ item.estimated_margin.toFixed(2) }} %</div>
            </template>
            <template v-slot:[`item.estimated_revenues`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item.estimated_revenues) }}
                </div>
            </template>
            <template v-slot:[`item.estimated_gross_profit`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item.estimated_gross_profit) }}
                </div>
            </template>
            <template v-slot:[`item.estimated_cost`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item.estimated_cost) }}
                </div>
            </template>
            <template v-slot:[`item.costs`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item.costs) }}
                </div>
            </template>
            <template v-slot:[`item.nomina`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item.nomina) }}
                </div>
            </template>
            <template v-slot:[`item.cost_of_revenues_(COP)`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item['cost_of_revenues_(COP)']) }}
                </div>
            </template>
            <template v-slot:[`item.percentage_of_completion`]="{ item }">
                <div class="my-4">
                    {{ (item.percentage_of_completition || 0).toFixed(2) }} %
                </div>
            </template>
            <template v-slot:[`item.revenues_earned`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item.revenues_earned) }}
                </div>
            </template>
            <template v-slot:[`item.gross_profit`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item.gross_profit) }}
                </div>
            </template>
            <template v-slot:[`item.total_revenues_for_project`]="{ item }">
                <div class="my-4">
                    {{
                        formatNumber(
                            'COP',
                            item[`total_revenues_${item.year}_for_project`]
                        )
                    }}
                </div>
            </template>
            <template v-slot:[`item.total_gross_profit_for_project`]="{ item }">
                <div class="my-4">
                    {{
                        formatNumber(
                            'COP',
                            item[`total_gross_profit_${item.year}_for_project`]
                        )
                    }}
                </div>
            </template>
            <template v-slot:[`item.revenues_earned_(COP)`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item['revenues_earned_(COP)']) }}
                </div>
            </template>
            <template v-slot:[`item.gross_profit_(COP)`]="{ item }">
                <div class="my-4">
                    {{ formatNumber('COP', item['gross_profit_(COP)']) }}
                </div>
            </template>
            <template v-slot:[`item.billed_to_date_for_this_month`]="{ item }">
                <div class="my-4">
                    {{
                        formatNumber(
                            'COP',
                            item['billed_to_date_for_this_month']
                        )
                    }}
                </div>
            </template>
            <template
                v-slot:[`item.estimated_cost_to_complete_(COP)`]="{ item }"
            >
                <div class="my-4">
                    {{
                        formatNumber(
                            'COP',
                            item['estimated_cost_to_complete_(COP)']
                        )
                    }}
                </div>
            </template>
        </v-data-table>
        <!--UPLOAD FILE-->
        <v-dialog v-model="openDialog" persistence max-width="400">
            <UploadPOC
                v-if="openDialog"
                @close="closeUploadFile"
                @addPOC="addPOC"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'POC',
    components: {
        UploadPOC: () => import('@/components/Projects/UploadPOC.vue'),
    },
    data: () => ({
        loading: false,
        openDialog: false,
        items: [],
        headers: [
            {
                text: 'Order CO',
                value: 'orden_CO',
                sortable: false,
                width: 100,
            },
            {
                text: 'Project ID',
                value: 'project_id',
                sortable: false,
                width: 100,
            },
            {
                text: 'Job',
                value: 'job',
                sortable: false,
                width: 250,
            },
            {
                text: 'Client',
                value: 'client',
                sortable: false,
                width: 150,
            },
            {
                text: 'Total amount before taxes',
                value: 'total_amount_before_taxes',
                sortable: false,
                width: 200,
            },
            {
                text: 'Balance to bill',
                value: 'balance_to_bill',
                sortable: false,
                width: 150,
            },
            {
                text: 'Costs',
                value: 'costs',
                sortable: false,
                width: 150,
            },
            {
                text: 'Nomina',
                value: 'nomina',
                sortable: false,
                width: 150,
            },
            {
                text: 'Billed to date',
                value: 'billed_to_date',
                sortable: false,
                width: 150,
            },
            {
                text: 'Estimated margin',
                value: 'estimated_margin',
                sortable: false,
                width: 150,
            },
            {
                text: 'Estimated revenues (COP)',
                value: 'estimated_revenues',
                sortable: false,
                width: 200,
            },
            {
                text: 'Estimated gross profit (COP)',
                value: 'estimated_gross_profit',
                sortable: false,
                width: 200,
            },
            {
                text: 'Estimated cost (COP)',
                value: 'estimated_cost',
                sortable: false,
                width: 200,
            },
            {
                text: '% completed',
                value: 'percentage_of_completition',
                sortable: false,
                width: 100,
            },
            {
                text: 'Revenues earned',
                value: 'revenues_earned',
                sortable: false,
                width: 200,
            },
            {
                text: 'Gross profit',
                value: 'gross_profit',
                sortable: false,
                width: 200,
            },
            {
                text: 'Cost of revenues (COP)',
                value: 'cost_of_revenues_(COP)',
                sortable: false,
                width: 200,
            },
            {
                text: 'Billed to date for this month',
                value: 'billed_to_date_for_this_month',
                sortable: false,
                width: 200,
            },
            {
                text: 'Estimated cost to complete (COP)',
                value: 'estimated_cost_to_complete_(COP)',
                sortable: false,
                width: 200,
            },
        ],
        projectToFilter: null,
        clientToFilter: null,
        dateToFilter: null,
        referenceToFilter: null,
        orderToFilter: null,
        uploadPocPermission: false,
    }),
    async mounted() {
        try {
            this.loading = true
            this.items = await API.getAllPOC()
            const {
                data: { user },
            } = await API.getMyInfo()
            this.uploadPocPermission = user.permissions.includes('uploadPOC')
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        filteredItems() {
            let conditions = []

            if (this.referenceToFilter) {
                conditions.push(this.filterByReference)
            }

            if (this.orderToFilter) {
                conditions.push(this.filterByOrder)
            }

            if (this.dateToFilter) {
                conditions.push(this.filterByDate)
            }

            if (this.projectToFilter) {
                conditions.push(this.filterByProject)
            }

            if (this.clientToFilter) {
                conditions.push(this.filterByClient)
            }

            if (conditions.length > 0) {
                return this.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            return this.items
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterByReference(item) {
            return `${item.project_id}`
                .toLowerCase()
                .includes(this.referenceToFilter.toLowerCase())
        },
        filterByOrder(item) {
            return `${item.orden_CO}`
                .toLowerCase()
                .includes(this.orderToFilter.toLowerCase())
        },
        filterByDate(item) {
            return `${item.year}-${item.month}`
                .toLowerCase()
                .includes(this.dateToFilter.toLowerCase())
        },
        filterByClient(item) {
            return item.client
                .toLowerCase()
                .includes(this.clientToFilter.toLowerCase())
        },
        filterByProject(item) {
            return item.job
                .toLowerCase()
                .includes(this.projectToFilter.toLowerCase())
        },
        formatNumber(currency, value) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
                maximumFractionDigits: currency == 'USD' ? 2 : 0,
            }).format(value)
        },
        addPOC(data) {
            if (data.oldVersions) {
                for (const id of data.oldVersions) {
                    this.items = this.items.filter(item => item.id != id)
                }
            }
            this.items = [...this.items, ...data.newVersions]
        },
        closeUploadFile() {
            this.openDialog = false
        },
        openUploadFile() {
            this.openDialog = true
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
.custom-label .v-label {
    font-size: 12px;
}
</style>
